import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import ModelInlineBase from "@/apps/core/models/modelInlineBase.js";
import { ToastProgrammatic as Toast } from "buefy";


const modelName = "matakuliah";
const inlineModelListName = "data_set";
const modelPath = `/${modelName}/`;

class MataKuliah extends ModelInlineBase {
  constructor() {
    let model = {
      id: null,
      kode: "",
      nama: "",
      kelompok: "",
      data_set: []
    };
    let inlineModel = { kuantitatif: "", kualitatif: "" };
    let inlineRequiredFields = ["kuantitatif", "kualitatif"];
    super(modelName, inlineModelListName, model, inlineModel,
      [], inlineRequiredFields, modelPath);
  }

  load(id, onLoaded) {
    let loadURL = `${this.apiURL}${id}`;
    this.observables.loading = true;
    axios.get(loadURL)
      .then(response => {
        let data = this.getLoadData(response.data);
        this.observables.matakuliah.id = data.id;
        this.observables.matakuliah.kode = data.kode;
        this.observables.matakuliah.nama = data.nama;
        this.observables.matakuliah.kelompok = data.kelompok;
        // this.setUpdate();
        if (onLoaded) {
          onLoaded();
        }
      })
      .catch(() => {
        // PERLU DICEK KEMBALI !!!!!!!!
        Toast.open("Data gagal dimuat.");
        this.reset();
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  reset() {
    super.reset();
    let apiURL = `${APP_CONFIG.baseAPIURL}${modelPath}`;
    this.setApiURL(apiURL);
  }

  getEmptyModel() {
    this.observables.matakuliah.data_set = [{ nama: null, jenis: null }];
    return this.observables.matakuliah;
  }
}

export default MataKuliah;