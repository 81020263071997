import APP_CONFIG from "@/apps/core/modules/config.js";
import { has, cloneDeep, isEqual } from "lodash";
import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";


class ModelInlineBase {
  constructor(modelName, inlineModelListName, model, inlineModel, requiredFields, inlineRequiredFields, urlPath) {
    // super(modelName, model, requiredFields, urlPath);

    this.modelName = modelName;
    this.emptyModel = model;
    this.apiURL = `${APP_CONFIG.baseAPIURL}${urlPath}`;
    this.validity = {
      edited: false,
      validated: false
    }
    this.requiredFields = requiredFields;
    this.commit = true;

    this.emptyInlineModel = inlineModel;
    this.inlineModelListName = inlineModelListName;
    this.emptyModel[inlineModelListName] = [this.emptyInlineModel];
    this.inlineRequiredFields = inlineRequiredFields;
    this.inlineRequiredFields.sort(); // untuk keperluan perbandingan
    this.initObservables();
  }

  setCommit(value) {
    this.commit = value;
  }

  getRequiredFields() {
    return this.requiredFields;
  }

  initObservables() {
    this.observables = {
      errorMap: this.getInitialErrorFields(),
      validity: this.validity,
      loading: false
    };
    this.observables[this.modelName] = cloneDeep(this.emptyModel);
  }

  getObservables() {
    return this.observables;
  }

  setUpdate() {
    this.observables.errorMap = this.getNoErrorMap();
    this.validity.validated = true;
    this.validity.edited = false;
  }

  setApiURL(apiURL) {
    this.apiURL = apiURL;
  }

  getEdited() {
    return this.validity.edited;
  }

  setEdited(val) {
    this.validity.edited = val;
    this.calcValidity();
  }

  getEmptyModel() {
    return cloneDeep(this.emptyModel);
  }

  clearNonFieldErrors() {
    this.observables.errorMap.non_field_errors = "";
  }

  isLoaded() {
    return this.observables[this.modelName].id !== null;
  }

  getLoadData(data) {
    return JSON.parse(JSON.stringify(data));
  }

  load(id, onLoaded) {
    let loadURL = `${this.apiURL}${id}/`;
    this.observables.loading = true;
    axios.get(loadURL)
      .then(response => {
        let data = this.getLoadData(response.data);
        this.observables[this.modelName] = data;
        this.setUpdate();
        if (onLoaded) {
          onLoaded();
        }
      })
      .catch(() => {
        // PERLU DICEK KEMBALI !!!!!!!!
        // perlu berikan pesan error toast atau snackbar
        // console.log(error);
        Toast.open("Data gagal dimuat.");
        this.reset();
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  create(onSaved) {
    const data = this.getPayload();
    const params = {commit: this.commit};
    this.observables.loading = true;
    axios.post(this.apiURL, data, { params: params })
      .then((response) => {
        if (this.commit) {
          this.reset();
          Toast.open("Data berhasil disimpan.");
        }

        if (onSaved) {
          onSaved(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {  // Perlu handle 403 juga
          this.updateErrorFields(error.response.data);
        }
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  getUpdateUrl() {
    return `${this.apiURL}${this.observables[this.modelName].id}/`;
  }

  update(onSaved) {
    let updateURL = this.getUpdateUrl();
    const data = this.getPayload();
    this.observables.loading = true;
    axios.patch(updateURL, data)
      .then((response) => {
        this.validity.edited = false;
        // this.reset();
        Toast.open("Data berhasil disimpan.");
        if (onSaved) {
          onSaved(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {  // Perlu handle 403 juga
          this.updateErrorFields(error.response.data);
        }
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  save(saveContext, onSaved) {
    if (saveContext === "create") {
      this.create(onSaved);
    } else if (saveContext === "update") {
      this.update(onSaved);
    }
  }

  reset() {
    this.observables[this.modelName] = this.getEmptyModel();
    this.observables.errorMap = this.getInitialErrorFields();
    this.validity.edited = false;
    this.validity.validated = false;
  }

  resetErrors() {
    this.observables.errorMap.non_field_errors = "";
    for (let key of this.requiredFields) {
      if (this.observables.errorMap[key] != "") {
        this.observables.errorMap[key] = null;
      }
    }
  }

  getInitialErrorFields() {
    let errorMap = { non_field_errors: "" };
    for (let key of this.requiredFields) {
      errorMap[key] = null;
    }
    let inlineErrorMap = { non_field_errors: "" };
    for (let key of this.inlineRequiredFields) {
      inlineErrorMap[key] = null;
    }
    errorMap.inlineErrorList = [inlineErrorMap];
    return errorMap;
  }

  getNoErrorMap() {
    let errorMap = { non_field_errors: "" };
    for (let key of this.requiredFields) {
      errorMap[key] = "";
    }

    errorMap.inlineErrorList = [];
    const inlineNum = this.observables[this.modelName][this.inlineModelListName].length;
    for (let i = 0; i < inlineNum; i++) {
      let inlineErrorMap = {};
      for (let key of this.inlineRequiredFields) {
        inlineErrorMap[key] = "";
      }
      errorMap.inlineErrorList.push(inlineErrorMap);
    }
    return errorMap;
  }

  updateErrorFields(respErrorMap) {
    /* digunakan untuk load error dari response */
    this.validity.validated = false;
    this.validity.edited = false;
    let errorMap = this.observables.errorMap;
    for (const key of Object.keys(errorMap)) {
      if (!Array.isArray(errorMap[key])) {  // ignore inlineError
        if (key in respErrorMap) {
          errorMap[key] = respErrorMap[key].join(". ");
        } else {
          errorMap[key] = "";
        }
      }
    }

    if (!has(respErrorMap, this.inlineModelListName)) return;

    let inlineErrorList = this.observables.errorMap.inlineErrorList;
    const inlineErrMap = inlineErrorList[0];
    for (const [idx, inlineErr] of respErrorMap[this.inlineModelListName].entries()) {
      for (const key of Object.keys(inlineErrMap)) {
        if (key in inlineErr) {
          inlineErrorList[idx][key] = inlineErr[key].join(". ");
        } else {
          inlineErrorList[idx][key] = "";
        }
      }
    }
  }

  getPayload() {
    /* Menghilangkan inline yang kosong sama sekali */
    let tdata = JSON.parse(JSON.stringify(this.observables[this.modelName]));
    delete tdata.id;
    let inlineSet = [];
    for (const iModel of tdata[this.inlineModelListName]) {
      let vals = Object.values(iModel);
      let hasData = vals.reduce((res, val) => res && !!val, true);
      if (hasData) {
        inlineSet.push(iModel);
      }
    }
    tdata[this.inlineModelListName] = inlineSet;
    return tdata;
  }

  calcValidity() {
    // check model validity
    let vals = Object.values(this.observables.errorMap);
    vals = vals.filter(el => !Array.isArray(el));
    let validated = vals.reduce((res, val) => res && val === "", true);

    // check has inline
    if (validated) {
      let hasInline = false;
      const inlineSet = this.observables[this.modelName][this.inlineModelListName];
      for (const iModel of inlineSet) {
        let hasAllReqVals = true;
        for (const key of this.inlineRequiredFields) {
          if (!iModel[key]) {
            hasAllReqVals = false;
            break;
          }
        }
        if (hasAllReqVals) {
          hasInline = true;
          break;
        }
      }
      validated = hasInline;
    }

    if (validated) {
      const errorList = this.observables.errorMap.inlineErrorList;
      // check inline validity
      inline:
      for (const iError of errorList) {
        for (const val of Object.values(iError)) {
          let validVal = val === "";
          if (!validVal) {
            validated = false;
            break inline;
          }
        }
      }
    }

    this.validity.validated = validated;
    // console.log("validate");
    // console.log(this.validity.edited);
    // console.log(this.validity.validated);
    // console.log(this.observables.errorMap);
    // console.log(this.modelName);
    // console.log(this.observables[this.modelName]);
    // console.log("----------------");
  }

  addInline() {
    const obvinlineSet = this.observables[this.modelName][this.inlineModelListName];
    let inlineSet = JSON.parse(JSON.stringify(obvinlineSet));
    inlineSet.push(cloneDeep(this.emptyInlineModel));
    this.observables[this.modelName][this.inlineModelListName] = inlineSet;

    let obvErrorList = this.observables.errorMap.inlineErrorList;
    let inlineErrorList = JSON.parse(JSON.stringify(obvErrorList));
    let inlineErrorMap = {};
    for (let key of this.inlineRequiredFields) {
      // set default tidak ada error supaya extra form dapat disubmit walaupun kosong
      inlineErrorMap[key] = "";
    }
    inlineErrorList.push(inlineErrorMap);
    this.observables.errorMap.inlineErrorList = inlineErrorList;
  }

  validate(field) {
    let fieldValArr = field ?
      [[field, this.observables[this.modelName][field]]] :
      Object.entries(this.observables[this.modelName]);

    for (const [fld, val] of fieldValArr) {
      if (Array.isArray(val)) continue;
      this.observables.errorMap[fld] = val ? "" : "Harus diisi.";
    }
    this.calcValidity();
  }

  validateInline(idx) {
    let inlineList = this.observables[this.modelName][this.inlineModelListName];
    let iModel = inlineList[idx];
    let errorList = this.observables.errorMap.inlineErrorList;
    let errorMap = errorList[idx];

    let fieldDataList = [];
    let fieldNoDataList = [];
    for (const key of Object.keys(errorMap)) {
      if (iModel[key]) {
        fieldDataList.push(key);
      } else {
        fieldNoDataList.push(key);
      }
    }

    fieldNoDataList.sort();
    fieldDataList.sort();
    const allOK = isEqual(fieldDataList, this.inlineRequiredFields) ||
      isEqual(fieldNoDataList, this.inlineRequiredFields);

    for (const key of Object.keys(errorMap)) {
      if (allOK) {
        errorMap[key] = "";
      } else {
        errorMap[key] = iModel[key] ? "" : "Harus diisi.";
      }
    }

    this.calcValidity();
    if (idx == inlineList.length - 1 && fieldDataList.length > 1 && allOK) {
      this.addInline();
    }
  }
}

export default ModelInlineBase;