<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">
            {{ headerText }} MATA KULIAH
          </p>
        </header>
        <div class="card-content events-card">
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Kode</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ matakuliah.kode }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail is-4 has-text-weight-semibold">
              Mata Kuliah
            </div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ matakuliah.nama }}</div>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
        <div v-if="isLoaded" class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field label="Kompetensi" custom-class="is-small"></b-field>
          <div
            v-for="(komp, idx) in matakuliah.data_set"
            :key="idx"
            class="columns no-margin"
          >
            <div class="column is-6">
              <b-field
                class="modul"
                horizontal
                :type="{
                  'is-danger': errorMap.inlineErrorList[idx].kuantitatif,
                }"
                :message="errorMap.inlineErrorList[idx].kuantitatif"
              >
                <b-input
                  v-model="komp.kuantitatif"
                  :class="{
                    noterror: !errorMap.inlineErrorList[idx].kuantitatif,
                  }"
                  placeholder="Kuantitatif"
                  @input="validateInline(idx)"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field
                class="modul"
                horizontal
                :type="{
                  'is-danger': errorMap.inlineErrorList[idx].kualitatif,
                }"
                :message="errorMap.inlineErrorList[idx].kualitatif"
              >
                <b-input
                  v-model="komp.kualitatif"
                  :class="{
                    noterror: !errorMap.inlineErrorList[idx].kualitatif,
                  }"
                  placeholder="Kualitatif"
                  @input="validateInline(idx)"
                ></b-input>
              </b-field>
            </div>
          </div>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
              >Simpan</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import MataKuliah from "../models/modulInlines.js";

export default {
  name: "MataKuliahCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  data() {
    this.matkulMdl = new MataKuliah();
    this.objectMdl = this.matkulMdl; // alias yg digunakan di cuMixin
    return this.matkulMdl.getObservables();
  },
  methods: {
    validateInline(idx) {
      this.matkulMdl.validateInline(idx);
    },
    fetchData() {
      this.objectMdl.load(this.$route.params.id);
    },
    saveObject() {
      let postURL = `${APP_CONFIG.baseAPIURL}/matakuliah/${this.matakuliah.id}/modul/`;
      this.objectMdl.setApiURL(postURL);
      this.objectMdl.create(() => {
        this.$router.go(-1);
      });
    },
  },
  watch: {
    matakuliah: {
      // mataKuliah berubah, set updated
      handler(newValue, oldValue) {
        if (!this.matkulMdl.getEdited()) {
          this.setEdited(!!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .noterror {
  input.is-danger {
    padding-right: unset !important;
    border-color: #dbdbdb !important;
  }

  .select.is-danger > select {
    // padding-right: unset !important;
    border-color: #dbdbdb !important;
  }

  .select.is-danger:not(:hover)::after {
    border-color: #dbdbdb !important;
  }

  .icon.has-text-danger {
    display: none;
  }

  + p {
    display: none;
  }
}

::v-deep .noterror:focus {
  border-color: #dbdbdb !important;
}

/* ::v-deep .nolabel { */
::v-deep .modul > .field-label {
  display: none !important;
}

@media screen and (min-width: 769px) {
  // ::v-deep .input-expanded {
  ::v-deep .modul > .field-body > .field:first-child {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  ::v-deep .field-body {
    width: 100%;
  }

  ::v-deep .modul > .field-body > .field:first-child {
    margin-right: 0;
  }
}

::v-deep .modul > .field-body > .field:not(:first-child) {
  flex: 1;
}

::v-deep .modul .select {
  width: 100%;

  select {
    width: 100%;
  }
}

::v-deep select:invalid,
select option:first-child {
  color: #999;
}
</style>
